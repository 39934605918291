











































































































































































































































import DetailTag from "@/components/UIComponents/DetailTag.vue";
import { Vue, Component } from "vue-property-decorator";
import { AdminRouter } from "@/utils/routePathContsant";
import driverStore from "@/store/modules/driver";

@Component({
  components: {
    DetailTag,
  },
})
export default class New extends Vue {
  async created() {
    const id = this.$route.query["id"];
    await driverStore.getDriverById(id);
  }

  get AdminRouter() {
    return AdminRouter;
  }

  get driver() {
    return driverStore.driver;
  }
}
